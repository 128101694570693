export default {
    "signin": {
        "signIn": "Sign in",
        "welcomeBack": "Welcome Back !",
        "introText": "IMean: your smart assistant in social networks.",
        "emailLabel": "Email Address",
        "inputPassword": "Input password",
        "passwordLabel": "Password",
        "forgotPassword": "Forgot password ?",
        "dontHaveAccount": "Don`t have an account?",
        "or": "or",
        "continue": "Continue",
        "signInGoogle": "Sign in with Google",
        "signInApple": "Sign in with Apple",
        "signInFacebook": "Sign in with Facebook",
        "signInLinkedIn": "Sign in with LinkedIn",
        "linkFacebook": "Link Facebook account",
        "linkLinkedIn": "Link LinkedIn account",
        "linkInstagram": "Link Instagram account",
        "linkedFacebook": "Facebook already linked",
        "unlinkedLinkedIn": "Unhook LinkedIn",
        "linkedInstagram": "Instagram already linked",
        "privacy": "The terms of the <offerLink>public offer</offerLink> and <privacyLink>privacy policy</privacyLink> have been fully read by me, I understand all the conditions, and I agree to all the terms",
        "payment": "Payment order",
        "contacts": 'WhatsApp: +7 700 600 0001 \r\n Email: info@imean.io',
        "agreePrivacy": "Please select “☑” the checkbox next to the text regarding reading and agreeing to the Public Offer and the Policy.",
        "privacyTitle": "Privacy Policy",
        "offerTitle": "Public offer",
        "chooseLang": "Choose a language that is comfortable for you"
    },
    "signup": {
        "signUp": "Sign Up",
        "fullName": "Full name",
        "passwordRequirements": "Ensure your password contains at least 8 characters - 1 uppercase, 1 lowercase, 1 number and 1 special symbol.",
        "haveAccount": "Already have an account?",
        "signUpGoogle": "Sign up with Google",
        "signUpApple": "Sign up with Apple",
        "signUpFacebook": "Sign up with Facebook",
        "signUpLinkedIn": "Sign up with LinkedIn",
        "lang": "Language"
    },
    "welcome": {
        "welcome": "Welcome {{username}} to IMean!",
        "howWeWork": "How we work",
        "completeSurvey": "In order to successfully create a personal brand, it is important to develop a strategy.",
        "completeSurvey2": "Based on this strategy, a content plan will be formed and publications will be prepared that take into account your goals and preferences.",
        "proceed": "Proceed to Survey",
        "warning": "It is very important to answer the questions as completely as possible so that the strategy is closer to your expectations.",
        "warning2": "In the process of our interaction with you, we will adjust it, from our point of view, for more accurate personalization."  
    },
    "assistant": {
        "header": "Assistant",
        "title": "Your personal assistant",
        "emptyChat": "No messages yet",
        "assistantInfo": "The assistant may make mistakes. We recommend checking important information.",
        "helloMessage": "Hello!\nI am your assistant and ready to help in various situations: prepare or reply to an email,\nshare the latest news, tell a joke, or even do a translation. I’m here to support\nyou in work, business, and many other matters. How can I assist you?",
        "textToGeneratePostFromAssistantMessage": "Generate a post based on this post with minimal changes",
        "createPostFromThisMessageBtn": "Create a post for LinkedIn based on this post",
        "chatSettingsTitle": "Chat settings",
        "useYourFile": "Use your file",
        "assistantWarningInfo": "You need to upload a file to access this function",
        "assistantSettingsInstructionTitle": "Test mode of the assistant's training.",
        "assistantSettingsInstructionContent": "Provide information to the assistant, which it will learn to interact with you. (In addition to this mode, the advanced assistant will soon have additional very cool features).",
        "assistantFilesInstructionTitle": "Instructions for Using the Assistant",
        "assistantFilesInstructionContent": `You can provide information for the assistant, which it will review and use when interacting with you. Follow these steps:
1. Prepare a Word file (.doc or .docx) containing the information you want the assistant to consider.
2. If you want to add new data to existing information, include the new details in the same file while keeping all previously added information intact.
3. Upload the file through the designated section of the service.
Once the file is processed, the assistant will use the provided information in its responses and recommendations.`,
        "uploadFile": "Upload file",
        "uploadNewFile": "Update file",
        "yourHaveFile": "You have an uploaded file.",
        "confirm": "Confirm",
        "fileUploadSuccess": "Successfully uploaded!",
    },
    "profile":{
        "cancelDelete": "Cancel the deletion",
        "accountWillBeDeleted": "Your account will be deleted",
        "recoverAccount": "Cancel the deletion",
        "removeAccountBtn": "Delete account",
        "yesRemoveAccount": "Yes, delete it.",
        "notRemoveAccount": "No, don't delete it",
        "removeAccountTitle": "Do you really want to delete the account?",
        "removingApproved": "Account deletion request successfully sent!",
        "recoverApproved": "Request to cancel account deletion has been successfully submitted!",
        "accountActionWait": "Wait...",
        "removeAccountDescription": "By clicking this button, you confirm that your data will be permanently deleted after 14 days. Once this period expires, After that, it will not be possible to restore the account.",
        "emptyReferrals": "You haven't invited anyone yet!",
        "status-accepted": "Invited",
        "status-pending": "Pending...",
        "status-gifted": "Reward received",
        "status-taken": "Invitation declined in favor of another",
        "close": "Close",
        "yourReferrals": "Your referrals",
        "reInvite": "Resend",
        "inviteSent": "The invitation to {{email}} has been sent",
        "inviteError": "There was an error when sending the invitation. This may be due to various reasons, perhaps this user is already registered.",
        "header": "Profile",
        "assistDescription": "Get the latest news and tips from an advanced assistant",
        "endSubscriptionDate": "Subscription expiration:",
        "endFreeSubscriptionDate": "The trial period will end:",
        "buySubscription": "Buy subscription",
        "upgradeSubscription": "Update plan",
        "extendSubscription": "Extend subscription",
        "cancelSubscription": "Cancel subscription",
        "comingSoon": "It won't be long now...",
        "soon": "Soon...",
        "name": "Name",
        "login": "Login",
        "subscription": "Subscription until",
        "no": "No",
        "yes": "Yes",
        "lang": "Language",
        "timeZone": "Time zone",
        "linkAccount": "Link account",
        "linkedInError": "Re-assign the account",
        "inviteFriend": "Invite a friend:",
        "invite": "Invite",
        "timeZoneModalTitle": "Time zones",
        "inviteModalTitle": "Refer a Friend and Get Half a Month for Free!",
        "timeZoneModalText": `
            We have introduced the option to select a time zone (e.g., UTC+5) specifically for your convenience, allowing you to fine-tune the scheduling of your posts. This helps you better target your audience in the desired region and achieve maximum reach.
            <br>
            Recommended posting times on LinkedIn:
            \t•\tMonday: 8:00 AM to 10:00 AM
            \t•\tTuesday: 9:00 AM to 12:00 PM
            \t•\tWednesday: 10:00 AM to 12:00 PM
            \t•\tThursday: 9:00 AM to 11:00 AM
            \t•\tFriday: 9:00 AM to 12:00 PM
            <br>
            Example usage:
            If you are in Kazakhstan (UTC+5) and want to post content for an audience in the USA (e.g., New York, UTC-5), simply select the appropriate time zone. This will ensure that your posts appear at 9:00 AM New York time when the audience is most active.
            <br>
            Major countries and their UTC offsets:
            Asia
            <br>
            \t•\tKazakhstan (Almaty): UTC+5
            \t•\tChina (Beijing): UTC+8
            \t•\tIndia (New Delhi): UTC+5:30
            \t•\tJapan (Tokyo): UTC+9
            \t•\tSouth Korea (Seoul): UTC+9
            \t•\tIndonesia (Jakarta): UTC+7
            Africa
            \t•\tSouth Africa (Johannesburg): UTC+2
            \t•\tEgypt (Cairo): UTC+2
            \t•\tNigeria (Lagos): UTC+1
            \t•\tKenya (Nairobi): UTC+3
            \t•\tMorocco (Rabat): UTC+0
            Latin America
            \t•\tBrazil (São Paulo): UTC-3
            \t•\tArgentina (Buenos Aires): UTC-3
            \t•\tMexico (Mexico City): UTC-6
            \t•\tColombia (Bogotá): UTC-5
            \t•\tChile (Santiago): UTC-3
            Europe
            \t•\tUnited Kingdom (London): UTC+0
            \t•\tGermany (Berlin): UTC+1
            \t•\tFrance (Paris): UTC+1
            \t•\tItaly (Rome): UTC+1
            \t•\tSpain (Madrid): UTC+1
            North America
            \t•\tUSA (New York, Eastern Time): UTC-5
            \t•\tUSA (Los Angeles, Pacific Time): UTC-8
            \t•\tCanada (Toronto): UTC-5
            \t•\tCanada (Vancouver): UTC-8
            Additional Notes:
            Please note that some countries have multiple time zones. If your target audience is in a different country, we recommend checking the appropriate UTC offset for optimal posting times.
            You can check local times using tools like timeanddate.com.
            <br>
            Set your time zone and maximize your audience’s attention! 😊
      `,
        "inviteModalText": `
        Want to earn additional free days of using our service?<br>
        Simply invite a friend! Here’s how it works:
        <br>
        <ol>
        <li><b>1. Share their contact details:</b> Provide your friend’s email address if you think they would be interested in our service.</li>
        <li><b>2. We’ll send an email:</b> We’ll send your friend an email (with you in copy), introducing our service and letting them know you invited them. This ensures your friend knows it’s not spam or a scam.</li>
        <li><b>3. Get half a month free:</b> If your friend registers and subscribes to a paid plan, you’ll automatically receive 15 days of free access to the same plan your friend selected.</li>
        <li><b>4. Your friend benefits too:</b> Your friend will also receive a <b>30% discount on their first month</b> of using the service. It’s a win-win for everyone!</li>
        </ol>
        <br>
        If you have any questions, feel free to contact us at <a class='text-purple-800' href="mailto:info@imean.io">info@imean.io</a> — we’ll be happy to help!
        `

    },
    "subscriptionPlans":{
        "header": "Rates",
        "Strategy": "Strategy",
        "ContentPlan": "Content plans",
        "LeadingQuestions": "Leading questions",
        "Post": "Posts",
        "Theme": "Themes",
        "PostImages": "Post images",
        "free": "Free",
        "paid": "Premium",
        "assistant": "Advanced Assistant",
        "infoButton": "Description of functionality",
        "modalTitle":"Functionality of the IMEAN.IO service:",
        "modalText": `
            iMean helps you easily and efficiently create LinkedIn posts using artificial intelligence. Our service offers:
             • AI-Powered Post Creation:
            Our service utilizes advanced artificial intelligence algorithms to generate unique content tailored to your professional identity and goals. You receive posts that align with your style, audience, and key topics in your field. This helps you stand out among competitors and build your personal brand.
             • Instant and Scheduled Posting:
            You can publish your posts on LinkedIn immediately or schedule them for a more convenient time. Scheduled posting allows you to maintain regular activity on the platform even when you’re busy. This feature ensures that your content reaches your audience at the most effective time, increasing engagement and impact.
             • Assistant:
            Our intelligent assistant is ready to answer your questions, help generate ideas for posts, translate text into other languages, draft business emails, and even suggest strategies for interacting with your audience. Communicate with it in a dialogue format, just like with a personal consultant who is always at your disposal. Moreover, you can ask the assistant to write a post and publish it directly on LinkedIn, saving your time and simplifying the publishing process.
             • Voice Input:
            Forget about typing — simply speak, and our service will convert your words into text. This feature is especially useful when you’re short on time or want to capture an idea on the go. Voice input works quickly and accurately, allowing you to focus on content rather than on typing.
        `,
        "submit": "Ok",
        "unlimitedRate":"Unlimited functionality",
        "assistantRate": "Unlimited Functional + personal AI assistant",
        "freeRate": "Free trial period for 14 days",
        "active": "Active",
        "offerExpire": "The offer will expire",
        "offerOver": "The offer's over",
        "subscribeExpired": "Subscription expired",
    },
    "survey": {
        "survey": "Survey",
        "recording": "Recording voice.. Click to stop recording",
        "type": "or type your answer here...",
        "wait": "Please wait, we are saving your answers and generating information. It might take some time",
        "clear": "Clear",
        "submit":"Submit",
        "tapToStart": "Click to dictate your answer",

        "title": "Please fill in the information to create content",

        "inputLabelName": "What is your name?",
        "inputPlaceholderName": "Enter your name",
        "inputInfoName": "This name will be used in the content",

        "inputLabelIndustry": "Industry",
        "inputPlaceholderIndustry": "The industry you work in",
        "inputInfoIndustry": "If your industry is not listed, you can enter it manually",

        "inputLabelPosition": "Position",
        "inputPlaceholderPosition": "Your position",
        "inputInfoPosition": "If your position is not listed, you can enter it manually",

        "textLabelWorkExperienceAndSkills": "Work experience and key skills",
        "textPlaceholderWorkExperienceAndSkills": "A brief description of your work experience, key skills and competencies, as well as projects you are proud of.",

        "textLabelPersonalityHobby": "Personal interests and hobbies",
        "textPlaceholderPersonalityHobby": "Information about hobbies and interests that can be included in posts to make them more human and authentic.",

        "textLabelSocialActivity": "Social activity and community involvement",
        "textPlaceholderSocialActivity": "Information about groups or communities you are actively involved in (e.g., unions, volunteering, professional groups).",

        "textLabelShortGoals": "Short-term career goals (1-2 years)",
        "textPlaceholderShortGoals": "Defining the immediate professional goals you want to achieve in the short term. This can include promotions, learning new skills, or successfully completing major projects.",

        "textLabelLongGoals": "Long-term career goals (3-5 years)",
        "textPlaceholderLongGoals": "In which direction do you want to grow over a longer period. What roles or positions do you see for yourself in the future? These goals help you focus on the strategic steps needed to achieve these career aspirations.",

        "textLabelUniqueTradeRelations": "Unique selling proposition (USP)",
        "textPlaceholderUniqueTradeRelations": "How do you stand out from other professionals in your field? This may include unique skills, approaches, or achievements that make you a valuable specialist in the job market.",

        "textLabelAchievements": "Awards and achievements",
        "textPlaceholderAchievements": "Professional awards, recognitions, certifications, or praise from colleagues and management that highlight your experience and contributions to the industry. This can include successful projects, leadership, or innovative solutions.",

        "textLabelProjects": "Projects and initiatives in progress",
        "textPlaceholderProjects": "Information about the current projects or initiatives you are working on that may be of interest to the audience. This shows your activity and engagement in the professional environment.",

        "textLabelStrongSides": "Your unique strengths",
        "textPlaceholderStrongSides": "Key competencies, strong skills, and personal qualities that help you successfully perform your job and excel in your professional activities. This may include leadership qualities, problem-solving abilities, communication skills, etc.",

        "textLabelWeakSides": "Weaknesses",
        "textPlaceholderWeakSides": "Aspects that you consider as areas for growth or improvement. These could be skill gaps or knowledge areas you want to work on in the future to become a more effective professional.",

        "selectLabelExperience": "Experience level",
        "selectPlaceholderExperience": "Choose Your experience level",

        "checkboxLabelThemes": "Topics for discussion",

        "checkboxLabelGoalsPublicLinkedIn": "Goals for publishing on LinkedIn",
    },
    "home": {
        "welcomeBack": "Welcome back, ",
        "logout": "Logout",
        "support": "Technical support",
        "guideline": "Useful tips",
        "contentPlan": "Create content plan",
        "personalAssistant": "Personal Assistant",
        "strategy": "Digital strategy",
        "createPost": "Create a post",
        "formatPost": "Publication format",
        "linkSocials": "Link social accounts",
        "profile": "Profile",
        "personalBrandStrategy": "A personal brand is a public image of a person leading a professional activity that appears in the minds of the target audience",
        "profileTitle": "Information about your profile, subscriptions, and invited friends",
        "contentPlanTitle": "This section stores information about upcoming posts",
        "createPostTitle": "Here you can quickly create and instantly publish a post.",
        "assistantTitle": "In dialogue mode, your personal assistant can translate text, write emails, and much more.",
        "strategyTitle": "This is where a strategy is created for you.",
        "subscriptionDaysLeft": "Number of days until subscription expires: {{days}}"
    },
    "personalBrand": {
        "needSurveyText": "To more accurately create your digital strategy, you need to take a survey",
        "coffee": `This might take 5-7 minutes.\r\nHow about a cup of invigorating coffee for some inspiration while the strategy is being formed?`,
        "header": "Digital strategy",
        "newStrategy": "Create new brand strategy",
        "Name": "Name",
        "Occupation/Title": "Title",
        "Short Bio": "Short Bio",
        "Accolades & Awards": "Accolades & Awards",
        "Strengths": "Strengths",
        "Weaknesses": "Weaknesses",
        "Passions": "Passions",
        "Values": "Values",
        "What Sets You Apart": "What Sets You Apart From The Peers",
        "Unique Benefits You Can Provide": "Unique Benefits You Can Provide",
        "Determine Who You are Trying to Reach and Communicate With": "Determine Who You are Trying to Reach and Communicate With",
        "Understand Their Needs, Wants, and Challenges": "Understand Their Needs, Wants, and Challenges",
        "How Should Daniel Address These Needs and Challenges": "How Should {{username}} Address These Needs and Challenges",
        "Colors": "Colors",
        "Imagery": "Imagery",
        "Personal Logo or Signature": "Personal Logo or Signature",
        "Areas of Expertise": "Areas of Expertise",
        "Content Formats": "Content Formats",
        "Content Themes": "Content Themes",
        "Engagement Strategy": "Engagement Strategy",
        "Short-term Goals (1-2 years)": "Short-term Goals (1-2 years)",
        "Long-term Goals (3-5 years)": "Long-term Goals (3-5 years)",
        "Feedback Channel": "Feedback Channel",
        "Adaptation Strategy": "Adaptation Strategy",
        "Conclusion": "Conclusion",
        "pdf": "Download PDF"
    },
    "sendPassword": {
        "header": "Forgot Password",
        "title": "Kindly input your email address to have a code sent",
        "placeholder": "Email address",
        "sendCode": "Send code"
    },
    "inputCode": {
        "header": "Input Code",
        "title": "Kindly input the code sent to your email address.",
        "placeholder": "Input code",
        "proceed": "Proceed"
    },
    "newPassword": {
        "noMatch": "Passwords do not match.",
        "header": "Create New Password",
        "passwordRequirements": "Ensure your password contains at least 8 characters - 1 uppercase, 1 lowercase, 1 number and 1 special symbol.",
        "placeholderCreate": "Create a new password",
        "placeholderConfirm": "Confirm password",
        "continue": "Continue"
    },
    "post": {
        "textToGenerateAnotherPost": "Write a similar one or a sequel to this post",
        "at": "at",
        "needSurveyText": "To more accurately create your digital strategy, you need to take a survey",
        "needSurveyRepeatText": "For the next generation of instant posts, you need to take a survey that will help you generate better posts",
        "leadingQuestions": "Leading questions",
        "noLeading1": "In order to write personalized post press Generate to have insightful questions for the perfect post",
        "noLeading2": "Tap 'Generate' for insightful questions for perfect post",
        "loading": "Loading...",
        "generate": "Generate",
        "headerPost": "Post",
        "headerCreate": "Create Post",
        "suggestion": "Post suggestion",
        "published": "Published",
        "notpublished": "Not published",
        "broken": "Error",
        "planned": "Scheduled for",
        "hashtagSuggestion": "Hashtag suggestions",
        "mediaSuggestion": "Media suggestions",
        "image": "Media files",
        "noImage": "No media files!",
        "noImageInfoNotDrag": "Drag the file(s) here or click to select",
        "noImageInfoWithDrag": "Release to load",
        "bigFileSize": "The file size is too large. Please choose another image up to 10mb",
        "maxVideoSize": "The file size is too large. Please choose another video up to 300mb",
        "uploadImage": "Upload Image",
        "processingImage": "Plase wait, we are processing image",
        "save": "Save",
        "generateImage": "Generate Image",
        "generatingImage": "Generating Image",
        "generating": "We are generating your post",
        "remove": "Remove",
        "addVideo": "Upload Video",
        "removeVideo": "Remove Video",
        "removeImages": "Remove Images",
        "maxVideoDuration": "The video duration is too long. Please choose another video up to 10min",
        "dragActive": "Drag the file(s) here or click to select",
        "onDrag": "Release to load",
        "dragInfo": "You can upload media files: videos up to 200mb and duration up to 10 minutes and images up to 10mb."
    },
    "contentPlan": {
        "header": "Content Plan",
        "week": "Week",
        "add": "Click + to select social networks",
        "frequency": "Frequency",
        "elementsTitle": "Select or add elements for this weeks content",
        "custom": "Write your custom content requests here",
        "create": "Create content plan",
        "tokenError": "LinkedIn is not linked",
        "strategyError": "You have not generated a personal strategy",
        "needSurveyText":"To create a content plan, you must complete a survey",
        "goToSurveyBtnText":"Take the survey",
    },
    "functions": {
        "generate": "Generate",
        "generating": "Generating...",
        "successfullyGenerated": "Successfully generated!",
        "successfullyFinished": "Successfully finished!",
        "successfullyUploaded": "Successfully uploaded!",
        "successfullySendToGenerate": "Successfully started the process of generating!",
        "wrong": "Something went wrong",
        "loading": "Loading...",
        "sending": "Sending...",
        "microphone": "Error accessing microphone, please check your device or give permission",
        "generatingPost": "Please wait, generating Post",
        "generatingContentPlan": "Please wait, generating Content Plan",
        "generatingBrand": "Please wait, generating Strategy",
        "loadingLeading": "Loading leading questions",
        "copied": "Copied !",
        "savingAnswersForStrategy": "Saving answers and generating strategy",
        "close": "Close",
        "loggedIn": "Successfully logged in!",
        "registered": "Successfully registered!",
        "incorrectCreds": "Incorrect credentials",
        "limitReached": "Action limit reached, used {{count}} of {{max}} by your rate",
        "recoveryRequested": "Recovery requested",
        "newPassword": "Password was reset",
        "error": "Error",
        "wrongLayout": "Wrong layout, need change language",
        "maxImages": "Maximum number of images - 6",
        "beta": "Beta",
    },
    "socials": {
        "header": "Social networks",
        "linkAccounts": "Link social network accounts to enable auto-posting"
    },
    "plancard": {
        "generated": "Post generated",
        "notGenerated": "Post not generated",
        "approved": "Approved",
        "notApproved": "Not approved",
        "publish": "Publish post",
        "approve": "Approve post",
        "disapprove": "Disapprove post",
      },
      "posts":{
        "header": "Posts",
        "generatePost": "Generate post",
        "postDescription": "Share with us the main idea or context of the planned post. You can use the microphone.",
        "postDetails": "Details",
        "postDate": "Publication date",
        "publish": "Publish",
        "regenerate": "Regenerate post",
        "limit": "The limit of the rate has been reached. You can subscribe to a paid rate to continue using the service.",
        "goToRate": "Go to rates",
        "chooseFormatTitle": "Choose format of publication",
        "acceptFormat": "Accept",
        "formatExampleText": "Example",
          "createAnotherPost": "Create a similar post",
          "seePost": "View post"
      },
    "support": {
        "header": "Technical support",
        "sectionLabel": "Select a section",
            "messageLabel": "Describe problem or suggestion",
            "fileLabel": "Attach file",
            "send": "Send",
            "uploadFile": "Uploaded files",
            "removeFile": "Remove",
            "sections": {
                "home-page": "Home-page",
                "profile-page": "Profile",
                "content-plan": "content-plan",
                "publication": "publication",
                "personal-assistant": "personal-assistant",
                "personal-branding": "personal-strategy",
            }

            },


      "hww":{
        "regTitle": "Registration in the service",
        "regSubtitle": "Register in the service",
        "strategyTitle": "Strategy Creation",
        "strategySubtitle": "Take the questionnaire and the AI will provide a personal strategy",
        "planTitle": "Creating content plan",
        "planSubtitle": "Based on the strategy, the AI will offer you a content plan",
        "postTitle": "Publishing posts",
        "postSubtitle": "Based on your strategy and content plan, the AI will suggest post ideas for you"
      },
      "weekDays": {
        "Monday": "Monday",
        "Tuesday": "Tuesday",
        "Wednesday": "Wednesday",
        "Thursday": "Thursday",
        "Friday": "Friday",
        "Saturday": "Saturday",
        "Sunday": "Sunday"
      }
}
