import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import noteIcon from "../../pages/icons/take-note.svg";
import micIcon from "../../pages/icons/mic.svg";
import arrowIcon from "../../pages/icons/arrow-right.svg";
import {Badge, Button, Drawer, message, Popover, Space} from "antd";
import axios from "axios";
import AudioRecorder from "audio-recorder-polyfill";
import {mergeStateObject} from "../../utils/merger";
import {fetchWrapper} from "../../helpers";
import {getLastArrayObjects} from "../../helpers/getLastArrayObjects";
import {useNavigate} from "react-router-dom";
import { CheckOutlined, CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Switch } from 'antd';
import takenIcon from "../../pages/icons/uncheck-icon.svg";
import crownIcon from "../../pages/icons/crown.png";
import checkIcon from "../../pages/icons/check-icon-black.svg";

const AssistantChat = () => {
	const maxLengthMessage = 800;
	window.MediaRecorder = AudioRecorder;
	const textAreaRef = useRef(null);
	const fileInputRef = useRef(null);
	const navigate = useNavigate();
	const [mediaRecorder, setMediaRecorder] = useState(null);
	const chatScrollContainer = useRef(null);
	const { t } = useTranslation();
	const [messages, setMessages] = useState([]);
	const [myMessage, setMyMessage] = useState("");
	const [drawerVisible, setDrawerVisible] = useState(false);
	const [fileData, setFileData] = useState({
		file: false,
		useFile: false,
		newFile: null,
	});
	const [loadings, setLoadings] = useState({
		sendMessage: false,
		recording: false,
		uploadAudio: false,
	});

	const content = (
			<pre style={{fontSize: '12px'}} className='text-[10px] block text-wrap text-pretty max-w-full font-inherit text-inherit'>
				{t('assistant.assistantFilesInstructionContent')}
			</pre>
	);

	const contentBeta = (
			<pre style={{fontSize: '12px'}} className='text-[10px] block text-wrap text-pretty max-w-full font-inherit text-inherit'>
				{t('assistant.assistantSettingsInstructionContent')}
			</pre>
	);

	const sendMessage = async () => {
		const lastMessage = myMessage;
		const nowDate = new Date();
		if (!myMessage.trim().length) return;
		message.loading(t("functions.sending"));
		mergeStateObject(setLoadings, {sendMessage: true});

		await setMessages(prevState => [...prevState, {
			role: "user",
			content: myMessage,
			date: nowDate,
		}]);

		await setMyMessage(() => '')

		await fetchWrapper.post(`${process.env.REACT_APP_API_URL}/assistant/chat`,
{
				content: myMessage,
				memo: getLastArrayObjects(messages, 10),
				useFile: fileData.useFile,
			}
		).then((data) => {
					setMessages(prevState => [...prevState, {
						role: data?.role,
						content: data?.content,
						date: data?.date || new Date(),
					}]);
					mergeStateObject(setFileData, {
						useFile: false,
					});
				})
				.catch((err)=>{
					setMyMessage(lastMessage);
					setMessages(prevState => [...prevState.filter((message, index) => message.date !== nowDate)]);
					message.error(t("functions.wrong"));
					console.error(err);
				});

		mergeStateObject(setLoadings, {sendMessage: false});
	};

	const startRecording = () => {
		navigator.mediaDevices
				.getUserMedia({ audio: true })
				.then((stream) => {
					mergeStateObject(setLoadings, {recording: true});
					const recorder = new MediaRecorder(stream);
					recorder.start();
					setMediaRecorder(recorder);
					recorder.addEventListener("dataavailable", (e) => {
						message.loading(t("functions.loading"));
						const audioBlob = new Blob([e.data], { type: "audio/wav" });
						const audioFile = new File([audioBlob], "recording.wav", {
							type: "audio/wav",
						});
						const formData = new FormData();
						formData.append("audio", audioFile);

						axios({
							method: "POST",
							url: `${process.env.REACT_APP_API_URL}/upload-audio`,
							headers: {
								"Content-Type": "multipart/form-data",
							},
							data: formData,
						})
								.then((data) => {
									if (myMessage.trim()){
										setMyMessage(prevState => `${prevState}\n${data.data}`);
									} else {
										setMyMessage(data.data);
									}
								}
								)
								.catch((error) => console.error(error))
								.finally(() => {
									mergeStateObject(setLoadings, {uploadAudio: false});
								});
					});
				})
				.catch((error) => {
					message.error(t("functions.microphone"));
				});
	};

	const stopRecording = () => {
		mergeStateObject(setLoadings, {recording: false});
		mediaRecorder?.stop();
	};

	const toggleMic = () => {
		if (loadings.recording) {
			stopRecording();
			mergeStateObject(setLoadings, {uploadAudio: true});
			if (textAreaRef.current){
				textAreaRef.current.focus();
			}
		} else {
			startRecording();
		}
	};

	const createPostFromMessage = (postText) => {
		const newPostDescription = `${postText.trim()}`;
		const date = new Date();
		fetchWrapper
				.post(`${process.env.REACT_APP_API_URL}/assistant/summary`, {
					content: newPostDescription,
					socialNetwork: "LinkedIn",
					date: date.toISOString(),
				})
				.then((res) => {
					if (res){
						message.loading(t("functions.generating"));
						navigate(`/create-post/${res.contentPlanId}`);
						// window?.location?.reload();
					}else {
						throw new Error('error');
					}
				})
				.catch((err) => {
					console.error(err);
					message.error(t("functions.wrong"));
				});
	};

	const handleFileChange = (e) => {
		const file = e.target.files[0];
		if (!file) {
			return;
		}
		mergeStateObject(setFileData, {newFile: file});
	};

	const handleFileUpload = () => {
		const formData = new FormData();
		formData.append("file", fileData.newFile);

		fetchWrapper.post(`${process.env.REACT_APP_API_URL}/assistant/files`, formData)
				.then((data)=>{
					if (data){
						message.success(t("assistant.fileUploadSuccess"));
						mergeStateObject(setFileData, {file: true, newFile: null,});
					}
				}).catch((err)=>{
					message.error(t("functions.wrong"));
					console.error(err)
		});

	};

	useEffect(() => {
		fetchWrapper.get(`${process.env.REACT_APP_API_URL}/assistant/history`).then((data) => {
			message.loading(t("functions.loading"));
			setMessages(data);
		}).catch((err)=>{
			message.error(t("functions.wrong"));
			console.error(err)
		});

		fetchWrapper.get(`${process.env.REACT_APP_API_URL}/assistant/files`).then((data) => {
			if (data.file){
				mergeStateObject(setFileData, {file: true});
			}
		}).catch((err)=>{
			message.error(t("functions.wrong"));
			console.error(err)
		});
	}, []);

	useEffect(() => {
		return () => {
			mediaRecorder?.state === "recording" && mediaRecorder.stop();
		};
	}, [mediaRecorder]);

	useEffect(() => {
		chatScrollContainer.current.scrollTop = chatScrollContainer.current.scrollHeight + 1000;
	}, [messages]);

	useEffect(() => {
		textAreaRef?.current?.focus();
	}, [loadings]);

	return (
			<div className='relative h-full w-full max-w-2xl mx-auto'>

				<div ref={chatScrollContainer}
						 className="relative w-full h-full flex flex-col px-5 py-16 scroll-hidden overflow-x-hidden overflow-y-auto">
					<div
							className='relative max-w-[90%] py-2 px-4 rounded-lg my-2.5 shadow-xl border bg-white text-primary mr-auto fade-in-from-left'>
							<pre className='block text-wrap text-pretty max-w-full font-inherit text-inherit'>
								{t("assistant.helloMessage")}
							</pre>
						<small className={`block mt-2 opacity-40`}>
							{new Date().toLocaleString()}
						</small>
					</div>
					{messages?.length ?
							messages.map((message, index) => (
									<div
											key={index}
											className={
												`relative max-w-[90%] py-2 px-4 rounded-lg my-2.5 shadow-xl border 
								${message?.role === "assistant" ? "bg-white text-primary mr-auto fade-in-from-left" : "bg-primary text-white ml-auto fade-in-from-right"}`
											}
									>
								<pre className='block text-wrap text-pretty max-w-full font-inherit text-inherit'>
									{message?.content}
								</pre>
										<div className={`flex flex-wrap-reverse ${message.role === "assistant" ? 'justify-between' : 'justify-end'} items-center mt-5 mb-2.5`}>
											<small className={`block py-1 opacity-40 ${message.role !== "assistant" && 'text-right'}`}>
												{new Date(message?.date).toLocaleString()}
											</small>
											{(message.role === "assistant" && (message?.content.toLowerCase().includes('пост') || message?.content.toLowerCase().includes('post'))) && (
												<button
														className='w-full sm:w-auto text-xs hover:bg-purple-500 hover:text-white py-1 px-1.5 rounded-xl text-center'
														onClick={()=> createPostFromMessage(message?.content)}
												>
													&#10004; {t("assistant.createPostFromThisMessageBtn")}
												</button>
											)}
										</div>
									</div>
							))
							:
							null
					}
				</div>

				<div className="fixed max-w-2xl bottom-0 w-full flex flex-col items-center bg-white py-1 px-2">
					<span className='absolute w-full h-3 bottom-full left-0 bg-gradient-to-t from-white to-transparent'/>
					<div className='w-full gap-2 flex items-start'>
						<div className='relative w-full'>
							<textarea
									ref={textAreaRef}
									autoFocus={true}
									disabled={loadings.recording || loadings.uploadAudio || loadings.sendMessage}
									placeholder={'Ваше сообщение...'}
									value={myMessage}
									onKeyDown={e => (e.key === "Enter" && e.ctrlKey) && sendMessage()}
									onChange={(e) => {
										if (e.target.value.length > maxLengthMessage) return;
										setMyMessage(e.target.value)
									}}
									className='mt-1.5 w-full border border-primary rounded-lg resize-none p-2 focus:outline-purple-600 disabled:opacity-80 disabled:border-gray-400'
									rows="4"
							/>

							<div className='w-full flex gap-2 items-center justify-between text-[10px]'>
							<span className='hidden md:flex items-center gap-1 '>
								<kbd
										className='inline-flex justify-center items-center py-0.5 px-1 bg-gray-200 border border-transparent font-mono text-gray-800 rounded-md'>
									<svg className="shrink-0 size-3" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
											 viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
											 strokeLinejoin="round"
											 data-darkreader-inline-stroke="">
										<path d="M15 6v12a3 3 0 1 0 3-3H6a3 3 0 1 0 3 3V6a3 3 0 1 0-3 3h12a3 3 0 1 0-3-3"></path>
									</svg>
									ctrl
								</kbd>
								<span>
									+
								</span>
								<kbd
										className='inline-flex justify-center items-center py-0.5 px-1 bg-gray-200 border border-transparent font-mono text-gray-800 rounded-md'>
									enter
								</kbd>
							</span>
								<span className='text-black/50 text-center'>
								{t("assistant.assistantInfo")}
							</span>
								<span
										className='pointer-events-none inline-flex justify-center items-center py-0.5 px-1 bg-gray-200 border border-transparent font-mono text-gray-800 rounded-md'
								>
								{myMessage.length}/{maxLengthMessage}
							</span>
							</div>

						</div>
						<div className='h-full flex flex-col gap-1 mt-1.5'>
							<button
									disabled={!myMessage.trim() || loadings.recording || loadings.uploadAudio || loadings.sendMessage}
									onClick={sendMessage}
									className='bg-primary text-white p-2 rounded-lg disabled:opacity-80'
							>
								<img src={arrowIcon} alt="Отправить"/>
							</button>

							<button
									disabled={loadings.uploadAudio || loadings.sendMessage}
									onClick={toggleMic}
									className={` text-white p-2 rounded-lg disabled:opacity-80 ${loadings.recording ? 'bg-red-500' : 'bg-primary'}`}
							>
								<img src={micIcon} alt="Микрофон"/>
							</button>

							<button
									disabled={loadings.uploadAudio || loadings.sendMessage}
									onClick={() => setDrawerVisible(true)}
									className={`text-white p-2 rounded-lg disabled:opacity-80 ${fileData.useFile ? 'bg-green-600' : fileData.file ? 'bg-primary' : 'bg-gray-400'}`}
							>
								<img src={noteIcon} alt="Доп. опции"/>
							</button>
						</div>
					</div>
				</div>

				<Drawer
						title={
					<Popover
							overlayStyle={{maxWidth: 400}}
							placement='bottomLeft'
							content={contentBeta}
							title={t("assistant.assistantSettingsInstructionTitle")}
						>
						<span/>
						<Badge.Ribbon
							placement='start'
							style={{
								top: -17,
								left: -12,
								width: 'fit-content'
							}}
							text={
							<div className='flex gap-2 items-center cursor-help text-sm'>
								<p className='uppercase'>
									{t("functions.beta")}
								</p>
								<span className='border-l pl-2 cursor-help'>
									<QuestionCircleOutlined className='text-white cursor-help' />
								</span>
							</div>
							}
							color='purple'
						>
									<span className='max-w-fit max-w-max'>
										{t("assistant.chatSettingsTitle")}
									</span>
						</Badge.Ribbon>
					</Popover>
						}
						placement={'bottom'}
						closable={false}
						onClose={() => setDrawerVisible(false)}
						open={drawerVisible}
						classNames={{
							mask: 'backdrop-blur-sm',
							content: 'h-full max-w-[648px] mx-auto overflow-hidden rounded-t-2xl',
							wrapper: 'h-full max-w-[648px] mx-auto overflow-hidden rounded-t-2xl'
						}}
						styles={{
							wrapper:{maxWidth:'648px', margin:'0 auto', borderRadius: '2rem 2rem 0 0', maxHeight: '500px'},
						}}
						extra={
							<Space>
								<Button type="default" onClick={() => setDrawerVisible(false)}>
									{t("profile.close")}
								</Button>
							</Space>
						}
				>
					<div className='flex items-center gap-1 border-b pb-3' >
						<Popover
								overlayStyle={{maxWidth: 500}}
								placement='bottomLeft'
								content={content}
								title={t("assistant.assistantFilesInstructionTitle")}
						>
							<QuestionCircleOutlined className='bg-primary text-white p-1 rounded-full text-xl cursor-help transition-opacity opacity-50 hover:opacity-100' />
						</Popover>
						<div className='w-full flex items-center gap-1' onClick={()=> !fileData.file? message.warning(t("assistant.assistantWarningInfo")) : null}>
							<label className='w-full cursor-pointer' htmlFor="useFile">{t("assistant.useYourFile")}</label>
							<Switch
									className={!fileData.file? 'pointer-events-none' : ''}
									id='useFile'
									checkedChildren={<CheckOutlined />}
									unCheckedChildren={<CloseOutlined />}
									disabled={!fileData.file}
									value={fileData.useFile}
									onChange={(checked) => mergeStateObject(setFileData, {useFile: checked})}
							/>
						</div>
					</div>
					<div className='flex flex-col justify-center items-center gap-2 mt-2'>
						{fileData.newFile ? (
								<>
									<div className='flex items-center gap-2'>
										<p className='text-xs'>{fileData.newFile.name}</p>
										<button
												className='text-red-600 font-bold text-xl'
												onClick={() => mergeStateObject(setFileData, {newFile: null})}
										>
											&#9932;
										</button>
									</div>
									<button className='bg-primary text-white px-4 py-2 rounded-lg' onClick={handleFileUpload}>
										{t('assistant.confirm')}
									</button>
								</>
						):(
								<>
									{fileData.file && <p className='text-xs'>{t('assistant.yourHaveFile')}</p>}
									<button className='bg-primary text-white px-4 py-2 rounded-lg'
													onClick={() => fileInputRef.current.click()}>
										{fileData.file ? t('assistant.uploadNewFile') : t('assistant.uploadFile')}
									</button>
									<input
											ref={fileInputRef}
											className='hidden'
											type="file"
											accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
											onChange={handleFileChange}
									/>
								</>
						)}
					</div>
				</Drawer>
			</div>
	);
};

export default AssistantChat;
